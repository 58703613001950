import {Md5} from 'ts-md5/dist/md5';

export class AuthenticationConfig {
	public accessToken : string;
	public refreshToken : string;
	public salt : string;

	constructor()
	{

	}

	setAccessToken (token) {
		this.accessToken = token;
	}
	
	getAccessToken () {
		return this.accessToken;
	}
	
	setRefreshToken (token) {
		this.refreshToken = token;
	}
	
	getRefreshToken () {
		return this.refreshToken;
	}
	
	setSalt (salt) {
		this.salt = salt;		
	}
	
	getSalt () {
		return this.salt;
	}
	
	getSeal () {
		return Md5.hashStr(this.getSalt() + this.getAccessToken());
	}
	
	getData () {
		return {
			accessToken: this.getAccessToken(),
			refreshToken: this.getRefreshToken(),
			salt: this.getSalt()
		};
	}	
}