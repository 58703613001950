
export class Config {
	
	public static getApiUrl () {
		// return 'http://localhost/abalanza.nl/app/';
		// return 'https://www.abalanza-portal.nl/app/v2/';
		return 'https://www.abalanza-portal.nl/app/v2/'; 
	}
	
	constructor()
	{

	}
}