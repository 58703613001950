import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

import { FirebaseX } from "@ionic-native/firebase-x/ngx";

import { Observable } from 'rxjs';

import { ToastController } from '@ionic/angular';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  public user: any = false;  
  public interval: any = false;  
  public moduleCount: any = {};

  public appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'fas fa-home'
    },
    {
      title: 'Modules',
      url: '/modules',
      icon: 'fas fa-graduation-cap'
    },
    {
      title: 'Lessen',
      url: '/courses',
      moduleKey: 'courses',
      icon: 'fas fa-book'
    },
    {
      title: 'Trainingen',
      url: '/trainings',
      moduleKey: 'trainings',
      icon: 'fas fa-running'
    },
    {
      title: 'Doelstellingen',
      url: '/goals',
      moduleKey: 'goals',
      icon: 'fas fa-bullseye'
    },
    {
      title: 'Vragenlijsten',
      url: '/questionnaires',
      moduleKey: 'questionnaires',
      icon: 'fas fa-clipboard-list'
    },
    {
      title: 'Voedingsschema\'s',
      url: '/mealschedules',
      moduleKey: 'mealSchedules',
      icon: 'fas fa-utensils'
    },
    {
      title: 'Score',
      url: '/badges',
      icon: 'fas fa-trophy'
    },
    {
      title: 'Mijn profiel',
      url: '/profile',
      icon: 'fas fa-user'
    },
    {
      title: 'Informatie',
      url: '/support',
      icon: 'fas fa-info-circle'
    },
    {
      title: 'Hulp',
      url: '/information',
      icon: 'far fa-question-circle'
    }
  ];

  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
	private firebaseX: FirebaseX,
	public toastController: ToastController, 
    private authenticationService: AuthenticationService
  ) {
    this.initializeApp();
  }
  
  initializeApp() {
    this.platform.ready().then(() => {
		this.statusBar.styleDefault();
		this.splashScreen.hide();
		
		var self = this;

		try {
			clearInterval(this.interval);
		} catch (e) {
			
		}
		
		self.interval = setInterval(function () {
			self.authenticationService.getActivitiesToday();
		},10000);

		this.authenticationService.authState.subscribe(state => {
			if (state) {
				self.user = this.authenticationService.currentUser;
				if (self.user.data.newBadge > 0) {
					this.router.navigate(['new-badge']);
				} else if (Object.keys(self.user.data.openSetByUserGoals).length > 0) { 
					this.router.navigate(['set-user-goals']);
				} else {
					this.router.navigate(['home']);  
				}
				self.moduleCount = self.user['data']['moduleCount'];
			} else {
				this.router.navigate(['login']);
			}
		});
	  
		// if login, store token
		this.authenticationService.authState.subscribe(state => {
			this.firebaseX.getToken().then(token => {
				this.authenticationService.saveFirebaseId(token);
			}).catch (function (e) {

			});
			
			this.firebaseX.onMessageReceived()
				.subscribe(data => console.log('Received'));
			
			this.firebaseX.onTokenRefresh().subscribe(token => {
				this.authenticationService.saveFirebaseId(token);
			});
		});

		if (this.platform.is('ios')) {

			 this.firebaseX.hasPermission().then(data => {

			  let permission = (data ? "granted" : "denied");

				  if (permission === 'granted') {
					  return;
				  }

				  this.firebaseX.grantPermission().then(data => {
				   
				  })
				   .catch(error => {
					  alert('Error getting permissions' + error);
					  return false;
					});

			  });
		}
	  
		this.authenticationService.newUserBadge.subscribe(state => {
			if (state != false && state != true) {
				let id = parseInt(state);
				if (id > 0) 
				{
					this.router.navigate(['new-badge']);
				}
			} 
		});
	  
		this.authenticationService.openSetUserGoals.subscribe(state => {
			if (state == true) {
				this.router.navigate(['set-user-goals']);
			} 
		});
	  
    });
  }
	  
	private async presentToast(message) {
		const toast = await this.toastController.create({
		  message,
		  duration: 3000
		});
		toast.present();
	}
	  
}
