
export class User {
	
	public id : number;
	public key : string;
	public profileImage : string;
	public firstname : string;
	public lastname : string;
	public username : string;
	public active : number;
	public data;
	
	constructor()
	{

	}
	
	setId (id) {
		this.id = id;
	}
	 
	getId () {
		return this.id;
	}
	
	setFirstname (firstname) {
		this.firstname = firstname;
	}
	
	getFirstname () {
		return this.firstname;
	}
	
	setLastname (lastname) {
		this.lastname = lastname;
	}
	
	getLastname () {
		return this.lastname;
	}
	
	setUsername (username) {
		this.username = username;
	}
	
	getUsername () {
		return this.username;
	}
	
	setProfileImage (profileImage) {
		this.profileImage = profileImage;
	}
	
	getProfileImage () {
		return this.profileImage;
	}
	
	setActive (active) {
		this.active = active;
	}
	
	getActive () {
		return this.active;
	}
	
	setData (data) {
		this.data = data;
	}
	
	getData () {
		return this.data;
	}
}