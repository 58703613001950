import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/authguard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule',
    canActivate: [AuthGuard]
  },
  { path: 'trainings', 	loadChildren: './pages/trainings/trainings.module#TrainingsPageModule',    canActivate: [AuthGuard]  },
  { path: 'trainings/:id', loadChildren: './pages/training-details/training-details.module#TrainingDetailsPageModule' ,    canActivate: [AuthGuard]   },
  { path: 'courses', loadChildren: './pages/courses/courses.module#CoursesPageModule',    canActivate: [AuthGuard] },
  { path: 'courses/:id', loadChildren: './pages/course-details/course-details.module#CourseDetailsPageModule',    canActivate: [AuthGuard] },
  { path: 'information', loadChildren: './pages/information/information.module#InformationPageModule' },
  { path: 'goals', loadChildren: './pages/goals/goals.module#GoalsPageModule',   canActivate: [AuthGuard] },
  { path: 'questionnaires', loadChildren: './pages/questionnaires/questionnaires.module#QuestionnairesPageModule' },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule',   canActivate: [AuthGuard] },
  { path: 'trainingswatch/:id', loadChildren: './pages/training-watch/training-watch.module#TrainingWatchPageModule' ,   canActivate: [AuthGuard] },
  { path: 'goals/:userModuleId/:goalId', loadChildren: './pages/goals-details/goals-details.module#GoalsDetailsPageModule' ,   canActivate: [AuthGuard] },
  { path: 'goalsanswer/:userGoalId', loadChildren: './pages/goals-answer/goals-answer.module#GoalsAnswerPageModule' ,   canActivate: [AuthGuard] },
  { path: 'questionnaires/:userModuleId/:questionnaireId', loadChildren: './pages/questionnaires-details/questionnaires-details.module#QuestionnairesDetailsPageModule' ,   canActivate: [AuthGuard] },
  { path: 'questionnaires/:userModuleId/:questionnaireId/:questionId', loadChildren: './pages/questionnaires-answers/questionnaires-answers.module#QuestionnairesAnswersPageModule' ,   canActivate: [AuthGuard] },
  { path: 'questionnaireform/:userQuestionnaireId', loadChildren: './pages/questionnaire-form/questionnaire-form.module#QuestionnaireFormPageModule' },
  { path: 'modules', loadChildren: './pages/modules/modules.module#ModulesPageModule',   canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule',   canActivate: [AuthGuard] },
  { path: 'modules/:id', loadChildren: './pages/module-details/module-details.module#ModuleDetailsPageModule',   canActivate: [AuthGuard] },
  { path: 'badges', loadChildren: './pages/badges/badges.module#BadgesPageModule',   canActivate: [AuthGuard] },
  { path: 'new-badge', loadChildren: './pages/new-badge/new-badge.module#NewBadgePageModule',   canActivate: [AuthGuard] },
  { path: 'set-user-goals', loadChildren: './pages/set-user-goals/set-user-goals.module#SetUserGoalsPageModule',   canActivate: [AuthGuard] },
  { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule',   canActivate: [AuthGuard] },
  { path: 'mealschedules', loadChildren: './pages/mealschedules/mealschedules.module#MealschedulesPageModule' },
  { path: 'mealschedules/:id', loadChildren: './pages/mealschedules-details/mealschedules-details.module#MealschedulesDetailsPageModule' },
  { path: 'mealschedules-edit-note/:id', loadChildren: './pages/mealschedules-edit-note/mealschedules-edit-note.module#MealschedulesEditNotePageModule' },
  { path: 'bulletin-board-index', loadChildren: './pages/bulletin-board-index/bulletin-board-index.module#BulletinBoardIndexPageModule' },
  { path: 'bulletin-board-delete/:id', loadChildren: './pages/bulletin-board-delete/bulletin-board-delete.module#BulletinBoardDeletePageModule' },
  { path: 'bulletin-board-form/:id/:parentId', loadChildren: './pages/bulletin-board-form/bulletin-board-form.module#BulletinBoardFormPageModule' },
  { path: 'chat', loadChildren: './pages/chat/chat.module#ChatPageModule' },
  { path: 'view-image/:id/:link', loadChildren: './pages/bulletin-board-form/view-image/view-image.module#ViewImagePageModule' },
  { path: 'bulletin-board-video/:id', loadChildren: './pages/bulletin-board-video/bulletin-board-video.module#BulletinBoardVideoPageModule' },
  { path: 'bulletin-board-form', loadChildren: './pages/bulletin-board-form/bulletin-board-form.module#BulletinBoardFormPageModule' },
  { path: 'bulletin-board-form', loadChildren: './pages/bulletin-board-form/bulletin-board-form.module#BulletinBoardFormPageModule' },
  { path: 'bulletin-board-video', loadChildren: './pages/bulletin-board-video/bulletin-board-video.module#BulletinBoardVideoPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
