
export class Company {
	public id : number;
	public banner : string;
	public logo : string;
	public email : string;
	public mainColor : string;
	public secondaryColor : string;
	public name : string;
	public number : string;
	public phone : string;
	public place : string;
	public street : string;
	public zipcode : string;
	public informationText : string;
	public introText : number;
	public appInformationTitle : string;
	
	public introModules : string;
	public introTrainings : string;
	public introGoals : string;
	public introQuestionnaires : string;
	public introCourses : string;
	public introScore : string;
	
	constructor()
	{

	}
	
	getAppInformationTitle () { return this.appInformationTitle; }
	setAppInformationTitle (appInformationTitle) { this.appInformationTitle = appInformationTitle; }
	
	getId () { return this.id;	}
	setId (id) { this.id = id; }
	
	getBanner () {	return this.banner; }
	setBanner (banner) { this.banner = banner; }
	
	getLogo () { return this.logo; }
	setLogo (logo) { this.logo = logo;	}
	
	getEmail () { return this.email; }
	setEmail (email) {	this.email = email; }
	
	getMainColor () { return this.mainColor; }
	setMainColor (mainColor) {	this.mainColor = mainColor; }
	
	getSecondaryColor () { return this.secondaryColor; }
	setSecondaryColor (secondaryColor) { this.secondaryColor = secondaryColor; }
	
	getName () { return this.name; }
	setName (name) { this.name = name; }
	
	getNumber () { return this.number; }
	setNumber (number) { this.number = number; }
	
	getPhone () { return this.phone; }
	setPhone (phone) {	this.phone = phone; }
	
	getPlace () { return this.place; }
	setPlace (place) {	this.place = place; }
	
	getStreet () { return this.street; }
	setStreet (street) { this.street = street; }
	
	getZipcode () {	return this.zipcode; }
	setZipcode (zipcode) {	this.zipcode = zipcode; }
	
	getInformationText () { return this.informationText;	}
	setInformationText (informationText) {	this.informationText = informationText; }
	
	getIntroModules () { return this.introModules;	}
	setIntroModules (introModules) {	this.introModules = introModules; }
	
	getIntroTrainings () { return this.introTrainings;	}
	setIntroTrainings (introTrainings) {	this.introTrainings = introTrainings; }
	
	getIntroGoals () { return this.introGoals;	}
	setIntroGoals (introGoals) {	this.introGoals = introGoals; }
	
	getIntroQuestionnaires () { return this.introQuestionnaires;	}
	setIntroQuestionnaires (introQuestionnaires) {	this.introQuestionnaires = introQuestionnaires; }
	
	getIntroCourses () { return this.introCourses;	}
	setIntroCourses (introCourses) {	this.introCourses = introCourses; }
	
	getIntroScore () { return this.introScore;	}
	setIntroScore (introScore) {	this.introScore = introScore; }
		
	getIntroText () { return this.introText; }
	setIntroText (introText) { this.introText = introText;	}
}